<template>
    <div>Bye!</div>
</template>

<script>
export default {
    name: `Signout`,
    mounted() {
        this.$auth.logout()
    }
}
</script>
